<template>
  <div class="min-h-screen">
    <ValidationObserver v-slot="{ handleSubmit }">
      <form
        @submit.prevent="handleSubmit(submitStepTwo)"
        enctype="multipart/form-data"
        class="px-6"
      >
        <!-- start: section -->
        <ValidationProvider
          name="IsParkingRequired"
          rules="required"
          v-slot="{ errors }"
        >
          <input-label :error="errors[0]" text="Vehicle Type *" />
          <t-rich-select
            placeholder="Select one"
            :options="getParkingRequiredOptions"
            valueAttribute="value"
            textAttribute="text"
            :hideSearchBox="true"
            :variant="{ danger: !!errors[0] }"
            v-model="form.is_parking_required"
          />
          <input-error-item :message="errors[0]" />
        </ValidationProvider>
        <!-- end: section -->

        <!-- start: section -->
        <div class="flex items-center">
          <div class="w-1/2 pr-2">
            <text-input rules="" name="" label="Vehicle name" />
          </div>
          <div class="w-1/2 pl-2">
            <ValidationProvider
              name="Type"
              rules="required"
              v-slot="{ errors }"
            >
              <input-label :error="errors[0]" text="Manufacturer *" />
              <t-rich-select
                placeholder="Select one"
                :options="getFleetReservationTimeOptions"
                valueAttribute="value"
                textAttribute="text"
                :hideSearchBox="true"
                :variant="{ danger: !!errors[0] }"
                v-model="form.reservation_time"
              />
              <input-error-item :message="errors[0]" />
            </ValidationProvider>
          </div>
        </div>
        <!-- end: section -->

        <!-- start: section -->
        <div class="flex items-center">
          <div class="w-1/2 pr-2">
            <ValidationProvider
              name="offride"
              rules="required"
              v-slot="{ errors }"
            >
              <input-label :error="errors[0]" text="Assign Lock *" />
              <t-rich-select
                placeholder="Select one"
                :options="getFrequencyOptions"
                valueAttribute="value"
                textAttribute="text"
                :hideSearchBox="true"
                :variant="{ danger: !!errors[0] }"
                v-model="form.off_ride_location_update_frequency"
              />
              <input-error-item :message="errors[0]" />
            </ValidationProvider>
          </div>
          <div class="w-1/2 pl-2">
            <ValidationProvider
              name="Type"
              rules="required"
              v-slot="{ errors }"
            >
              <input-label :error="errors[0]" text="Country *" />
              <t-rich-select
                placeholder="Select one"
                :options="getFrequencyOptions"
                valueAttribute="value"
                textAttribute="text"
                :hideSearchBox="true"
                :variant="{ danger: !!errors[0] }"
                v-model="form.on_ride_location_update_frequency"
              />
              <input-error-item :message="errors[0]" />
            </ValidationProvider>
          </div>
        </div>
        <!-- end: section -->

        <!-- start: section -->
        <div>
          <ValidationProvider
            name="Address"
            rules="required"
            v-slot="{ errors }"
          >
            <input-label :error="errors[0]" text="Description *" />
            <t-textarea
              rules="required"
              v-model="form.description"
            ></t-textarea>
            <input-error-item :message="errors[0]" />
          </ValidationProvider>
        </div>
        <!-- end: section -->
        <div class="mt-8 font-semibold text-oBlack">Vehicle Condition</div>
        <label class="flex items-center w-full my-2 cursor-pointer">
          <input
            type="checkbox"
            class="form-checkbox focus:shadow-none text-oBlack focus:border-oDark border-oBlack"
            v-model="supportMailConfig"
          />
          <span class="ml-3 font-normal select-none text-14px text-oBlack">
            IOT Fault
          </span>
        </label>

        <label class="flex items-center w-full my-2 cursor-pointer">
          <input
            type="checkbox"
            class="form-checkbox focus:shadow-none text-oBlack focus:border-oDark border-oBlack"
            v-model="supportMailConfig"
          />
          <span class="ml-3 font-normal select-none text-14px text-oBlack">
            Available
          </span>
        </label>

        <button type="submit" ref="stepTwoSubmitButton" class="hidden">
          Save
        </button>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
import xMan from '@/utils/xMan'
import { EventBus } from '@/utils/EventBus'
import { FleetConfig } from '@/config/FleetConfig'
import { DropdownConfig } from '@/config/DropdownConfig'
import { mapState } from 'vuex'
import { mapGetters } from 'vuex'

import InputLabel from '@/components/form/InputLabel'
import TextInput from '@/components/form/TextInput'

export default {
  name: 'AddEditVehicleStep2',
  components: {
    InputLabel,
    TextInput,
  },
  data: function() {
    return {
      isLoading: false,
      form: {},
      supportMailConfig: false,
      // selected vehicle types
      vehicleTypes: [],
      // selected subscription plan
      subscription: undefined,
      subscriptionId: undefined,
      // all subscription plans detail
      subscriptionPlans: undefined,
      // getBillingPlanOptions: undefined
    }
  },
  computed: {
    ...mapGetters('organization', [
      'isEditing',
      'currentEditingOrganizationId',
    ]),
    ...mapState({
      // retrieve organization detail from step 1
      step1: (state) => state.fleet.step1,
      // retrieve subscription detail from step 2
      step2: (state) => state.fleet.step2,
    }),
    getParkingRequiredOptions: function() {
      return [
        {
          value: 'YES',
          text: 'Yes',
        },
        {
          value: 'NO',
          text: 'No',
        },
      ]
    },
    getFleetReservationTimeOptions: function() {
      return [
        {
          value: 5,
          text: '5 Min',
        },
        {
          value: 10,
          text: '10 Min',
        },
        {
          value: 15,
          text: '15 Min',
        },
        {
          value: 20,
          text: '20 Min',
        },
        {
          value: 25,
          text: '25 Min',
        },
        {
          value: 30,
          text: '30 Min',
        },
      ]
    },
    getMaxSpeedLimitOptions: function() {
      return [
        {
          value: 5,
          text: 5,
        },
        {
          value: 10,
          text: 10,
        },
        {
          value: 15,
          text: 15,
        },
        {
          value: 20,
          text: 20,
        },
      ]
    },
    getFrequencyOptions: function() {
      return [
        {
          value: 1,
          text: '1S',
        },
        {
          value: 2,
          text: '2S',
        },
        {
          value: 3,
          text: '3S',
        },
        {
          value: 4,
          text: '4S',
        },
        {
          value: 5,
          text: '5S',
        },
        {
          value: 6,
          text: '6S',
        },
        {
          value: 7,
          text: '7S',
        },
        {
          value: 8,
          text: '8S',
        },
        {
          value: 9,
          text: '9S',
        },
        {
          value: 10,
          text: '10S',
        },
      ]
    },
  },
  async created() {
    // todo: remove logs, push to errors array
    this.subscriptionPlans = await this.$http
      .get(DropdownConfig.api.subscription)
      .then((res) => res.data.data)
      .catch((err) => console.log(err))
    // if (this.isEditing) {
    //   // console.log(this.step2.subscriptionId);
    //   this.subscriptionId = this.step2.subscriptionId;
    //   this.vehicleTypes = this.step2.vehicleTypes;
    // }
  },
  watch: {
    // subscriptionId: function(updatedSubscriptionId) {
    //   this.subscription = this.subscriptionPlans.find(subscription => {
    //     return subscription.id === updatedSubscriptionId
    //       ? subscription
    //       : undefined;
    //   });
    // }
  },
  methods: {
    submit: function() {
      // // todo: remove fake completion
      // EventBus.$emit(OrganizationConfig.events.succeededStep2, {
      //   subscription: "ab04ced6-5aea-4eb8-b9f2-b3e122b9f2c1"
      // });
      console.log(this.step1)
      // todo: directly call here?
      this.submitStepTwo()
    },
    submitStepTwo: async function() {
      this.isLoading = true

      let method = this.isEditing ? 'PATCH' : 'POST'
      let url = this.isEditing
        ? FleetConfig.api.update(this.step1.id)
        : FleetConfig.api.create
      let data = new xMan({
        ...this.step1,
        ...this.form,
        // organization: ""
      }).toFormData()
      let message = this.isEditing
        ? `Fleet updated successfully`
        : `Fleet added successfully`

      // todo: remove logs
      console.log('step2 started submitting')
      // console.log({ step2: data });
      // console.log({ step2: url });

      try {
        let response = await this.$http({ method, url, data })
        console.log(typeof response)
        console.log({ res: response.data })
        this.isLoading = false
        // this.form = {};
        this.$notify(
          {
            group: 'important',
            type: 'success',
            title: 'Success',
            text: message,
          },
          5000
        )

        // Resolved
        EventBus.$emit(FleetConfig.events.succeededStep2, {
          res: response.data,
        })
        return true
      } catch (e) {
        // todo: remove logs
        // todo: show server errors
        this.isLoading = false
        // todo: handle server errors
        // console.log({ sdhf: typeof error });
        // console.log({ e1: Object.keys(error) });
        console.log({ e2: e })
        // for (let i = 0; i < e.response.data.length; i++) {
        //   this.errors.push(e.response.data[i]);
        // }
        // Rejected
        // throw new Error(error.status);
      }
    },
    getSelectedClass: function(val) {
      return {
        selected: this.vehicleTypes.includes(val),
      }
    },
  },
}
</script>

<style lang="scss" scoped></style>
