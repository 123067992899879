<template>
  <base-layout>
    <add-edit-vehicle />
    <content-section :spacing="false">
      <div class="content-spacing">
        <div class="grid grid-cols-1 mb-10 md:grid-cols-2">
          <div class="">
            <title-plus title="Vehicle Management" @plus="add" />
          </div>
          <div class="flex md:justify-end">
            <date-range-picker
              @apply-date="handleApplyDate($event)"
              @cancel-date="handleCancelDate($event)"
            />
          </div>
        </div>

        <div
          class="flex flex-col mt-4 mb-10 space-y-4 md:space-y-0 md:items-center md:flex-row"
        >
          <card-tax-stat
            title="Total Vehicles"
            :value="indexMetaData.summary.total"
            variant="yellow"
            class="mr-3"
          />
          <card-tax-stat
            title="On Ride"
            :value="indexMetaData.summary.on_ride"
            variant="light-blue"
            class="mr-3"
          />
          <card-tax-stat
            title="Idle"
            :value="indexMetaData.summary.idle"
            variant="orange"
            class=""
          />
        </div>
        <div class="grid grid-cols-1 md:grid-cols-2">
          <div>
            <oto-search v-model="search" />
          </div>
          <div class="flex md:justify-end ">
            <export-as-dropdown />
          </div>
        </div>
      </div>
      <fsTableContainer class="vld-parent">
        <loading :active.sync="isLoading" :is-full-page="false" />
        <fsTable>
          <fsTableHead>
            <fsTableHeadItem
              v-for="(item, index) in tableHeaders"
              :key="`fs-table-header-${index}`"
              :item="item"
              @apply-sorting="handleSorting($event)"
            />
          </fsTableHead>

          <fsTableRow
            v-for="(item, index) in indexData"
            :key="`fs-table-row-${index}`"
          >
            <fsTableRowItem>
              <router-link
                class="font-bold text-blue-700 capitalize"
                :to="{
                  name: 'ViewVehicleProfile',
                  params: { id: item.id },
                }"
              >
                #{{ item.bike_short_id }}
              </router-link>
            </fsTableRowItem>

            <fsTableRowItem>
              {{ getVehicleType(item.bike_category) }}
            </fsTableRowItem>

            <fsTableRowItem>
              <battery-status :level="item.lock.power_level" />
            </fsTableRowItem>

            <fsTableRowItem>
              <div
                v-if="item.lock"
                class="font-semibold text-blue-700 cursor-pointer"
              >
                {{ item.lock.qr_code }}
              </div>
              <span v-else>--</span>
            </fsTableRowItem>

            <fsTableRowItem>
              {{ $localTime(item.last_connected_at) }}
            </fsTableRowItem>

            <fsTableRowItem>
              {{ $localTime(item.last_ride_at) }}
            </fsTableRowItem>

            <fsTableRowItem>
              {{ item.vehicle_conditions }}
            </fsTableRowItem>

            <fsTableRowItem>
              <HeartbeatStatus :status="item.heart_beat" />
            </fsTableRowItem>

            <fsTableRowItem>
              <div class="flex items-center">
                <!-- <oto-edit-icon @click="edit(item)" />
                <MoreActionsDropdown
                  :currentVal="item.lock.is_operational"
                  :onOffEndpoint="getActionsEndpoint(item.id)"
                  actionOnKey="is_operational"
                  actionOffKey="is_operational"
                  :actionOnVal="true"
                  :actionOffVal="false"
                  @actionOn="item.lock.is_operational = true"
                  @actionOff="item.lock.is_operational = false"
                /> -->
                <div class="map-icon">
                  <i class="fas fa-map"></i>
                </div>
                <t-toggle
                  variant="success"
                  :checked="item.lock.is_locked"
                  @change="onOff(item, $event)"
                />
              </div>
            </fsTableRowItem>
          </fsTableRow>
        </fsTable>

        <fs-table-foot
          :total-items="indexMetaData.count.total"
          :pagination-summary="paginationSummary"
        />
      </fsTableContainer>
    </content-section>
  </base-layout>
</template>

<script>
import BaseLayout from '@/views/shared/BaseLayout.vue'
import ContentSection from '@/components/layout/ContentSection'
import TitlePlus from '@/components/ui/TitlePlus'
import OtoSearch from '@/components/ui/OtoSearch'
import ExportAsDropdown from '@/components/dropdown/ExportAsDropdown'
// import OtoEditIcon from "@/components/ui/OtoEditIcon";
// import XStatus from "@/components/badge/XStatus";
import BatteryStatus from '@/components/badge/BatteryStatus'
import HeartbeatStatus from '@/components/badge/HeartbeatStatus'

import isLoadingMixin from '@/mixins/isLoadingMixin'
import queryStringMixin from '@/mixins/queryStringMixin'
import queryStringSearchMixin from '@/mixins/queryStringSearchMixin'
import queryStringFiltersMixin from '@/mixins/queryStringFiltersMixin'
import getIndexDataMixin from '@/mixins/getIndexDataMixin'
import fsTableUiMixin from '@/mixins/fsTableUiMixin'
import { VehicleConfig } from '@/config/VehicleConfig'
// import { LockConfig } from "@/config/LockConfig";

import { EventBus } from '@/utils/EventBus'
import CardTaxStat from '@/components/cards/TaxStat'
import DateRangePicker from '@/components/picker/date-range/DateRangePicker'
import AddEditVehicle from '@/views/vehicle/AddEditVehicle.vue'
// import MoreActionsDropdown from "@/components/dropdown/MoreActionsDropdown";

export default {
  name: 'Vehicles',
  mixins: [
    isLoadingMixin,
    queryStringMixin,
    queryStringSearchMixin,
    queryStringFiltersMixin,
    getIndexDataMixin,
    fsTableUiMixin,
  ],
  components: {
    BaseLayout,
    ContentSection,
    TitlePlus,
    ExportAsDropdown,
    OtoSearch,
    // OtoEditIcon,
    DateRangePicker,
    // XStatus,
    BatteryStatus,
    HeartbeatStatus,
    CardTaxStat,
    AddEditVehicle,
    // MoreActionsDropdown
  },
  data() {
    return {
      indexMetaData: {
        summary: {
          total: 0,
          on_ride: 0,
          idle: 0,
        },
        count: {
          total: 0,
        },
      },
      indexDataEndpoint: VehicleConfig.api.index,
      typeChoice: {
        P: 'Scooter',
        S: 'Bike',
        E: 'E-Bike',
      },
      tableHeaders: [
        {
          name: 'ID',
          width: '10%',
          field: 'bike_short_id',
          sort: null,
          order: '',
        },
        {
          name: 'Type',
          width: '10%',
          field: 'bike_category',
          sort: true,
          order: '',
        },
        {
          name: 'Battery',
          width: '10%',
          field: 'power_level',
          sort: null,
          order: '',
        },
        {
          name: 'Lock QR Code',
          width: '10%',
          field: 'lock',
          sort: true,
          order: '',
        },
        {
          name: 'Last Connected',
          width: '15%',
          field: 'last_connected_at',
          sort: true,
          order: '',
        },
        {
          name: 'Last Ride',
          width: '15%',
          field: 'last_ride_at',
          sort: true,
          order: '',
        },
        {
          name: 'Vehicle Conditions',
          width: '10%',
          field: 'vehicle_conditions',
          sort: true,
          order: '',
        },
        {
          name: 'Heart Beat',
          width: '10%',
          field: 'heart_beat',
          sort: null,
          order: '',
        },
        {
          name: 'Actions',
          width: '10%',
          field: null,
          sort: null,
          order: '',
        },
      ],
    }
  },
  async mounted() {
    // todo: remove the event listener
    // todo: create a refresh index data mixin ?
    window.addEventListener(VehicleConfig.events.refresh, () => {
      this.getIndexData(this.getQueryString)
    })
  },
  methods: {
    getVehicleType: function(vehicle_type) {
      // console.log(type)
      return vehicle_type in this.typeChoice
        ? this.typeChoice[vehicle_type]
        : '-'
    },
    add: function() {
      EventBus.$emit(VehicleConfig.events.editingData, {})
      dispatchEvent(new Event(VehicleConfig.events.sorToggle))
    },
    edit: function(item) {
      console.log(item)
      // EventBus.$emit(SubscriptionConfig.events.editingData, item);
      // dispatchEvent(new Event(SubscriptionConfig.events.sorToggle));
    },
    view: function(item) {
      console.log(item)
      // EventBus.$emit(SubscriptionConfig.events.viewingData, item);
      // this.$modal.show(SubscriptionConfig.events.viewingData);
    },
    getActionsEndpoint(id) {
      return VehicleConfig.api.update(id)
    },
    onOff(item, val) {
      console.log(item)
      let data = new FormData()
      data.append('is_locked', val)
      // this.$http
      //   .patch(LockConfig.api.update(item.lock.id), data)
      //   .then(res => {
      //     console.log("res====", res);
      //   })
      //   .catch(err => {
      //     console.log("resErr", err);
      //   });
    },
  },
}
</script>

<style>
.map-icon {
  display: grid;
  place-items: center center;
  font-size: 14px;
  font-weight: normal;
  text-align: center;
  color: #ffffff;
  width: 27px;
  height: 27px;
  border-radius: 5px;
  background-color: #ff598a;
  cursor: pointer;
  margin-right: 0.5rem;
}
</style>
