<template>
  <div :class="['step custom-step', classes]">
    <input
      class="input"
      type="radio"
      :id="id"
      :name="computedName"
      :checked="active"
      @change="handleChange"
      v-show="debug"
    />
    <label class="label" :for="id">
      <span class="index">
        <slot name="index" v-bind="api" v-if="active || disabled">
          {{ api.displayIndex }}
        </slot>
        <slot v-else>
          <i class="m-auto fas fa-check" style="font-size: 8px;"></i>
        </slot>
      </span>
      <span class="title" v-if="defaultSlot">
        <slot v-bind="api"></slot>
      </span>
      <span class="divider" v-if="withDivider"></span>
    </label>
  </div>
</template>

<script>
export default {
  name: 'VStep',
  props: {
    index: {
      type: Number,
      default: 0,
    },
    name: {
      type: String,
      default: '',
    },
    active: {
      type: Boolean,
      default: false,
    },
    visited: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    withDivider: {
      type: Boolean,
      default: false,
    },
    debug: {
      type: Boolean,
      default: true,
    },
  },
  data: () => ({
    namespace: 'v-step',
  }),
  computed: {
    id() {
      return `${this.namespace}-${this._uid}-${this.index}`
    },
    displayIndex() {
      return this.index + 1
    },
    classes() {
      return {
        'is-active': this.active,
        'is-visited': this.visited,
        'is-disabled': this.disabled,
      }
    },
    computedName() {
      return this.name || this.id
    },
    defaultSlot() {
      return this.$slots.default || this.$scopedSlots.default
    },
    api() {
      const { displayIndex, defaultSlot } = this
      return { displayIndex, defaultSlot }
    },
  },
  methods: {
    handleChange() {
      this.$emit('change', this.index)
    },
  },
  inheritAttrs: false,
}
</script>

<style lang="scss" scoped>
@import './sass/variables';

*,
*::before,
*::after {
  box-sizing: inherit;
}

.step {
  flex: 1;
  opacity: 0.55;
  box-sizing: border-box;
  transition: opacity 0.4s;
  color: $app-color-white;
  padding: 6px 8px;
  margin-right: 1rem;
  border-radius: 99rem;
  border: 1px solid $app-color-dusty-gray;

  &:last-child {
    margin-right: 0;
  }

  .label {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .index {
    display: flex;
    flex-shrink: 0;
    place-content: center;
    align-items: center;
    width: 1.5rem;
    height: 1.5rem;
    font-size: 14px;
    border-radius: 50%;
    //font-size: 0.65rem;
    color: #ffffff;
    padding: 0px;
    margin: 0px;
    background-color: #000000;
  }

  .title {
    margin-left: 7px;
    font-size: 14px;
    color: $app-color-mirage;
  }

  .divider {
    width: 100%;
    margin-left: 0.5rem;
    border-bottom: 1px solid $app-color-black;
    box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
  }
  // States
  &:hover:not(.is-disabled) {
    opacity: 0.85;
  }

  // todo: change is-visited state after adding is-success state
  &.is-visited {
    opacity: 1;
    border: 1px solid $app-color-visited-green;
    .label {
      .title {
        color: $app-color-visited-green;
        //font-family: Roboto;
        font-size: 15px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #00bb3d;
      }
      .index {
        color: $app-color-white;
        background-color: $app-color-visited-green;
      }
    }
  }
  &.is-disabled {
    opacity: 0.3;
    .title {
      //font-family: Roboto;
      font-size: 15px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: $app-color-black;
    }
  }

  &.is-active {
    opacity: 1;
    border: 1px solid $app-color-mirage;

    .label {
      .title {
        //font-family: Roboto;
        font-size: 15px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #000000;
      }
      .index {
        color: $app-color-white;
        background-color: $app-color-black;
      }
    }
  }

  // todo: add success state
  &.is-success {
    opacity: 1;
    border: 1px solid $app-color-visited-green;
    .label {
      .title {
        color: $app-color-visited-green;
        //font-family: Roboto;
        font-size: 15px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #00bb3d;
      }
      .index {
        color: $app-color-white;
        background-color: $app-color-visited-green;
      }
    }
  }
}
</style>
