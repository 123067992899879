<template>
  <slide-over-right :id="sorId" v-on:showModal="showModal">
    <loading :active.sync="isLoading"></loading>

    <template v-slot:header>
      <div class="panel-title">{{ titleText }}</div>
      <div class="w-full lg:w-3/5">
        <stepper
          :steps="steps"
          v-model="currentStep"
          ref="stepper"
          :with-divider="false"
        >
          <template v-slot:step-1> IOT Info </template>
          <template v-slot:step-2> Vehicle </template>
        </stepper>
      </div>
    </template>

    <template v-slot:footer>
      <div class="flex items-center justify-end mt-6">
        <slide-over-right-trigger :id="sorId">
          <anchor-button variant="secondary">Cancel</anchor-button>
        </slide-over-right-trigger>
        <t-button type="submit" class="ml-3" @click="submitStep(currentStep)">
          Save & Continue
        </t-button>
      </div>
    </template>

    <div v-if="errors.length > 0" class="pl-6 text-red-500">
      <li v-for="(error, index) in errors" :key="index">{{ error }}</li>
    </div>

    <template v-if="currentStep === 1">
      <add-edit-vehicle-step-1 ref="step1" />
    </template>
    <template v-if="currentStep === 2">
      <add-edit-vehicle-step-2 ref="step2" />
    </template>
  </slide-over-right>
</template>

<script>
import { mapState } from 'vuex'
import { mapGetters } from 'vuex'

import { EventBus } from '@/utils/EventBus'
import { FleetConfig } from '@/config/FleetConfig'
import { VehicleConfig } from '@/config/VehicleConfig'

import SlideOverRight from '@/components/modals/SlideOverRight'
import SlideOverRightTrigger from '@/components/modals/SlideOverRightTrigger'
import AnchorButton from '@/components/form/AnchorButton.vue'

import Stepper from '@/components/stepper/Stepper'

import AddEditVehicleStep1 from '@/views/vehicle/AddEditVehicleStep1'
import AddEditVehicleStep2 from '@/views/vehicle/AddEditVehicleStep2'

export default {
  name: 'AddEditVehicle',
  components: {
    SlideOverRight,
    SlideOverRightTrigger,
    AnchorButton,
    Stepper,
    AddEditVehicleStep1,
    AddEditVehicleStep2,
  },
  data() {
    return {
      isLoading: false,
      // editing data
      item: {},
      // The organization id that will be edited
      // organizationId: undefined,
      errors: [],
      sorId: VehicleConfig.events.sorId,
      // stepper
      steps: 2,
    }
  },
  computed: {
    ...mapGetters('organization', [
      'isEditing',
      'currentEditingOrganizationId',
    ]),
    titleText() {
      return this.isEditing ? 'Edit Vehicle' : 'Add Vehicle'
    },
    actionButtonText: function() {
      return this.isEditing ? 'Update' : 'Save'
    },
    currentStep: {
      get() {
        return this.$store.getters['fleet/currentStep']
      },
      set(updatedStep) {
        this.$store.dispatch('fleet/saveStep', {
          step: updatedStep,
        })
      },
    },
    ...mapState({
      step1data: (state) => state.fleet.step1,
      step2data: (state) => state.fleet.step2,
    }),
  },
  async mounted() {
    // listeners
    EventBus.$on(VehicleConfig.events.editingData, (item) => {
      // remove old store
      this.$store.dispatch('fleet/forgetEditingStep')
      this.$store.dispatch('fleet/forgetAllStepsData')
      this.$store.dispatch('fleet/forgetEditingFleetId')
      // todo: forget last opened step?

      // pass data to step-* & trigger step-* from here
      this.item = item
      if (Object.keys(item).length > 1) {
        this.populateOrganizationStore(this.item.id)
      }
      // this.organizationId = this.item.id || undefined;
      // todo: remove logs
      console.log('edit-triggered', this.organizationId)
      console.log({ item })
      // console.log(this.isEditing);
      this.$nextTick(() => {
        let self = this
        console.log(self.$refs)
      })
    })

    EventBus.$on(FleetConfig.events.succeededStep1, (item) => {
      this.item = item
      // this.form.step1 = { ...this.item };
      this.$store.dispatch('fleet/saveStepData', {
        step: 1,
        data: item,
      })
      this.$store.dispatch('fleet/nextStep', {
        step: 2,
      })
      console.log('aeF', this.currentStep)
      console.log('aeF', this.step1data)
      console.log('aeF-item', item)
      this.$refs.stepper.next()
    })

    EventBus.$on(FleetConfig.events.succeededStep2, (item) => {
      this.$store.dispatch('fleet/saveStepData', {
        step: 2,
        data: item,
      })
      console.log('aef2', this.currentStep)
      console.log('aef2', this.step2data)
      // // todo: clear the store?
      this.$store.dispatch('fleet/forgetStep')
      this.$store.dispatch('fleet/forgetAllStepsData')

      // todo: close the sor & do other things
      dispatchEvent(new Event(FleetConfig.events.sorClose))
      // todo: Refetch the indexData
      dispatchEvent(new Event(FleetConfig.events.refresh))
    })
  },
  methods: {
    showModal() {
      console.log('exit')
      dispatchEvent(new Event(VehicleConfig.events.sorToggle))
    },
    submitStep: function(step) {
      this.$refs[`step${step}`].submit()
    },
    populateFleetStore: async function(fleetId) {
      console.log(fleetId)
      // todo: clear store if id is undefined/empty
      /*  await this.$http
        .get(OrganizationConfig.api.single(organizationId))
        .then(res => {
          let org = res.data;
          this.$store.dispatch("organization/saveEditingOrganizationId", {
            data: organizationId
          });
          this.$store.dispatch("organization/saveEditingStep", {
            step: 1
          });
          this.$store.dispatch("organization/saveStepData", {
            step: 1,
            data: {
              id: org.id,
              avatar: org.avatar,
              name: org.name,
              phone_number: org.phone_number,
              alias: org.alias,
              // dropdown
              country: org.country.id,
              // dropdown
              city: org.city.id,
              zip_code: org.zip_code,
              // dropdown
              // todo: proper format needed
              default_fleet: "",
              // dropdown
              organization_category: org.organization_category,
              domain: org.domain,
              port: org.port,
              address: org.address
            }
          });
          this.$store.dispatch("organization/saveStepData", {
            step: 2,
            data: {
              subscriptionId: org.subscription.id,
              vehicleTypes: org.vehicle_type
            }
          });
          this.$store.dispatch("organization/saveStepData", {
            step: 3,
            data: {
              payment: {
                id: org.default_payment_gateway_config.id,
                payment_gateway:
                  org.default_payment_gateway_config.payment_gateway,
                client_id: org.default_payment_gateway_config.client_id,
                secret_key: org.default_payment_gateway_config.secret_key,
                app_key: org.default_payment_gateway_config.app_key,
                extra_key: org.default_payment_gateway_config.id
              },
              form: {
                play_store_link: org.play_store_link,
                apple_store_link: org.apple_store_link,
                website_link: org.website_link,
                email_address: org.email_address
              }
            }
          });
          this.$store.dispatch("organization/saveStepData", {
            step: 4,
            data: {
              owner_user: {
                ...org.owner_user
              },
              form: {
                full_name: org.owner_user.full_name,
                username: org.owner_user.username,
                phone_number: org.owner_user.phone_number,
                email: org.owner_user.email
              }
            }
          });

          console.log("aftr-gt-popu-suc", this.$store.state.organization.step1);
          console.log(
            "aftr-gt-popu-suc-id",
            this.$store.state.organization.editingOrganizationId
          );
        })
        .catch(err => {
          console.log("popu-err", err);
        }); */
    },
  },
}
</script>

<style scoped>
.panel-title {
  font-size: 22px;
  font-weight: 500;
  color: #2e2e39;
  margin-top: -30px;
  margin-bottom: 6px;
}
</style>
