<template>
  <ValidationObserver v-slot="{ handleSubmit }">
    <form
      @submit.prevent="handleSubmit(submitStepOne)"
      enctype="multipart/form-data"
      class="px-6"
    >
      <!-- start: section -->
      <div class="flex items-center">
        <div class="w-1/2 pr-2">
          <text-input
            rules="required"
            label="Name"
            placeholder="Fleet name"
            v-model="form.name"
          />
        </div>
        <div class="w-1/2 pl-2">
          <text-input
            rules="required"
            label="QR Code"
            placeholder="QR Code"
            v-model="form.qr_code"
          />
        </div>
      </div>
      <!-- end: section -->

      <!-- start: section -->
      <div class="flex items-center">
        <div class="w-1/2 pr-2">
          <text-input
            rules="required"
            label="Manufacturer"
            placeholder="Manufacturer"
            v-model="form.manufacturer"
          />
        </div>
        <div class="w-1/2 pl-2">
          <text-input
            rules="required"
            label="MSISDN"
            placeholder="123 456 7890"
            v-model="form.msisdn"
          />
        </div>
      </div>
      <!-- end: section -->

      <!-- start: section -->
      <div class="flex items-center">
        <div class="w-1/2 pr-2">
          <text-input
            rules="required"
            label="ICCID"
            placeholder="ICCID"
            v-model="form.iccid"
          />
        </div>
        <div class="w-1/2 pl-2">
          <text-input
            rules="required"
            label="IMEI"
            placeholder="IMEI"
            v-model="form.imei"
          />
        </div>
      </div>
      <!-- end: section -->

      <!-- start: section -->
      <div class="flex items-center">
        <div class="w-1/2 pr-2">
          <text-input
            rules="required"
            label="Factory QR"
            placeholder="Factory QR"
            v-model="form.factory_qr"
          />
        </div>
        <div class="w-1/2 pl-2">
          <text-input
            rules="required"
            label="Firmware Type"
            placeholder="Firmware Type"
            v-model="form.FIirmware_type"
          />
        </div>
      </div>
      <!-- end: section -->

      <!-- start: section -->
      <div class="flex items-center">
        <div class="w-1/2 pr-2">
          <text-input
            rules="required"
            label="Firmware Key"
            placeholder="Firmware Key"
            v-model="form.firmware_key"
          />
        </div>
        <div class="w-1/2 pl-2">
          <ValidationProvider
            name="Country"
            rules="required"
            v-slot="{ errors }"
          >
            <input-label :error="errors[0]" text="Lock Condition *" />
            <t-rich-select
              :options="getLockConditionOtions"
              valueAttribute="value"
              textAttribute="text"
              :hideSearchBox="true"
              placeholder="Select one"
              :variant="{ danger: !!errors[0] }"
              v-model="form.lock_condition"
            />
            <input-error-item :message="errors[0]" />
          </ValidationProvider>
        </div>
      </div>
      <!-- end: section -->

      <!-- start: section -->
      <div class="flex items-center">
        <div class="w-1/2 pr-2">
          <text-input
            rules="required"
            label="Fleet"
            placeholder="Longitude"
            v-model="form.warehouse_lon"
          />
        </div>
        <div class="w-1/2 pl-2">
          <text-input
            rules="required"
            label="Latitude"
            placeholder="Latitude"
            v-model="form.warehouse_lat"
          />
        </div>
      </div>
      <!-- end: section -->

      <!-- start: section -->
      <div class="flex items-center">
        <div class="w-1/2 pr-2">
          <text-input
            rules="required"
            label="Registration Date"
            placeholder="01/20/2020"
            v-model="form.registration_date"
          />
        </div>
        <div class="w-1/2 pl-2">
          <text-input
            rules="required"
            label="ICCID Number"
            placeholder="123 456 7890"
            v-model="form.iccid_number"
          />
        </div>
      </div>
      <!-- end: section -->

      <button type="submit" ref="stepOneSubmitButton" class="hidden">
        Save
      </button>
    </form>
  </ValidationObserver>
</template>

<script>
import xMan from '@/utils/xMan'
import { EventBus } from '@/utils/EventBus'
import { FleetConfig } from '@/config/FleetConfig'
import { DropdownConfig } from '@/config/DropdownConfig'

import { mapGetters } from 'vuex'

// import UploadAvatar from "@/components/form/UploadAvatar";
import TextInput from '@/components/form/TextInput'
import InputLabel from '@/components/form/InputLabel'

export default {
  name: 'AddEditVehicleStep1',
  components: {
    // UploadAvatar,
    InputLabel,
    TextInput,
  },
  data: function() {
    return {
      isLoading: false,
      item: {},
      form: {},
      useCompanyDetails: false,
      // country & city
      countries: undefined,
      cities: undefined,
      cityIsLoading: false,
    }
  },
  computed: {
    ...mapGetters('fleet', ['isEditing', 'currentEditingFleetId']),
    step1: function() {
      return this.$store.state.fleet.step1
    },
    getCountry: function() {
      return this.form.country || undefined
    },
    getCityDisabledState: function() {
      if (!this.form.country) return true
      if (this.cityIsLoading) return true
      return false
    },
    getCityPlaceholder: function() {
      if (!this.form.country) return 'Select a country first'
      if (this.cityIsLoading) return 'Loading ⟳'
      return 'Select city'
    },
    getLockConditionOtions: function() {
      return [
        {
          value: 'GOOD',
          text: 'Good',
        },
        {
          value: 'MEDIUM',
          text: 'Medium',
        },
        {
          value: 'SUPER',
          text: 'Super',
        },
      ]
    },
    // getDefaultFleetPlaceholder: function() {
    //   if (this.isEditing) return "N/A";
    //   return "Select default fleet";
    // },
    // getDefaultFleetOptions: function() {
    //   // if (this.isEditing) {
    //   //   // todo: fetch fleets in editng mode
    //   //   return [
    //   //     {
    //   //       id: 1,
    //   //       name: "Test"
    //   //     },
    //   //     {
    //   //       id: 2,
    //   //       name: "Nest"
    //   //     }
    //   //   ];
    //   // }
    //   return [
    //     {
    //       id: "",
    //       name: "N/A"
    //     }
    //   ];
    // },
    // getDefaultFleetValidationRules: function() {
    //   // todo: re-enable default-fleet validation in editing mode after getting data in proper format
    //   // if (this.isEditing) return "required";
    //   return "";
    // },
    // getOrganizationTypeOptions: function() {
    //   return [
    //     {
    //       id: "TESTING",
    //       name: "Testing"
    //     },
    //     {
    //       id: "LIVE",
    //       name: "Live"
    //     }
    //   ];
    // }
  },
  async created() {
    // todo: remove logs, push to errors array
    this.countries = await this.$http
      .get(DropdownConfig.api.country)
      .then((res) => res.data.data)
      .catch((err) => console.log(err))
  },
  // mounted() {
  //   EventBus.$on(OrganizationConfig.events.editingStep1Data, item => {
  //     // todo: remove logs
  //     console.log({ step1: item });
  //     console.log({ step1: this.isEditing });

  //     this.item = item;
  //     this.form = { ...this.item };
  //   });
  // },
  // beforeDestroy() {
  //   EventBus.$off(OrganizationConfig.events.editingStep1Data);
  //   EventBus.$off(OrganizationConfig.events.succeededStep1);
  // },
  watch: {
    getCountry: async function(updatedCountry) {
      this.form.city = this.isEditing ? this.form.city : undefined
      this.cityIsLoading = true

      this.cities = await this.$http
        .get(DropdownConfig.api.city(updatedCountry))
        .then((res) => res.data.data)
        .catch((err) => console.log(err))
        .finally(() => (this.cityIsLoading = false))
    },
    currentEditingFleetId: function(updatedId) {
      console.log(this.currentEditingFleetId)
      console.log('wae1', this.step1)
      if (updatedId !== undefined) {
        this.form = {
          ...this.step1,
        }
        return
      }
      this.form = {}
      return
    },
  },
  methods: {
    submit: function() {
      // // todo: remove simulated succeeded event
      // EventBus.$emit(OrganizationConfig.events.succeededStep1, {
      //   id: "16f9f9d2-97de-4154-8dc8-d5c45b174139",
      //   name: "Supra Bike",
      //   created_at: "2020-11-22 07:00:29",
      //   country: {
      //     id: 1,
      //     name: "South Africa"
      //   },
      //   status_active: false
      // });
      // Resolved
      EventBus.$emit(FleetConfig.events.succeededStep1, this.form)
      // // todo: directly call method?
      // this.$refs.stepOneSubmitButton.click();
      // alert(this.currentEditingOrganizationId);
    },
    submitStepOne: async function() {
      this.isLoading = true

      console.log('s1-sub', this.isEditing)
      let method = this.isEditing ? 'PATCH' : 'POST'
      let url = this.isEditing
        ? FleetConfig.api.update(this.currentEditingFleetId)
        : FleetConfig.api.create
      if (typeof this.form.avatar === 'string') {
        delete this.form.avatar
      }
      let data = new xMan(this.form).toFormData()
      let message = this.isEditing
        ? 'Fleet updated successfully'
        : 'Fleet added successfully'

      // todo: remove logs
      console.log('step1 started submitting')
      console.log({ step1: this.isEditing })
      console.log({ step1: this.form })

      console.log({ step1: data })
      console.log({ step1: url })

      try {
        let response = await this.$http({ method, url, data })
        this.isLoading = false
        // todo: remove logs
        console.log({ res: response.data })
        // Reset the form
        this.form = {}
        // Notify
        this.$notify(
          {
            group: 'important',
            type: 'success',
            title: 'Success',
            text: message,
          },
          5000
        )
        // Resolved
        EventBus.$emit(FleetConfig.events.succeededStep1, response.data)
        return true
      } catch (e) {
        // todo: remove logs
        // todo: show server errors
        this.isLoading = false
        // todo: handle server errors
        // console.log({ sdhf: typeof error });
        // console.log({ e1: Object.keys(error) });
        console.log({ e1: e.response.data })
        // for (let i = 0; i < e.response.data.length; i++) {
        //   this.errors.push(e.response.data[i]);
        // }
        // Rejected
        // throw new Error(error.status);
      }
    },
  },
}
</script>
